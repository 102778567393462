<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="600px"
      v-if="resolutionScreen >= 500"
    >
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
      </template>-->

      <v-card>
        <v-card-title>
          <!-- <v-layout row wrap align-center class="mt-3"> -->
          <!-- <v-flex lg2>
              <v-btn
                :disabled="enableback"
                @click="querydirectories(parentdirectory)"
                small
                fab
                class="mr-2"
                :color="color.theme"
              >
                <v-icon :color="color.chipText">keyboard_backspace</v-icon>
              </v-btn>
            </v-flex> -->
          <!-- <v-flex lg9> -->
          <!-- <span style="color: black; font-size: 18px;" v-if="filedata.file_type === 'folder'"
                >{{ $t("movefiles.movefolder") }} {{ filedata.file_name }}</span
              >
              <span style="color: black; font-size: 18px;" v-else>{{ $t("movefiles.movefiles") }} {{ filedata.file_name }}</span>
              <v-spacer></v-spacer> -->
          <!-- </v-flex> -->
          <!-- <v-icon class="text-right" :color="color.theme" dense dark size="35">mdi-folder-swap</v-icon> -->
          <!-- </v-layout> -->
          <v-layout row wrap>
            <v-flex lg11 sm11 xs10 class="pl-2">
              <v-list-item-title style="font-size: 18px;"
                >{{
                  filedata.file_type === "folder"
                    ? $t("movefiles.movefolder")
                    : $t("movefiles.movefiles")
                }}
                {{ filedata.file_name }}</v-list-item-title
              >
            </v-flex>
            <v-flex lg1 sm1 xs2>
              <v-icon
                class="text-right"
                :color="color.theme"
                dense
                dark
                size="35"
                >mdi-folder-swap</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-title>
        <!-- <v-card-title v-if="filedata.file_type === 'folder'"><v-btn :disabled="enableback" @click="querydirectories(parentdirectory)" icon small text class="mr-2"><v-icon >keyboard_backspace</v-icon></v-btn> ย้ายโฟลเดอร์ {{ filedata.file_name }}</v-card-title>
        <v-card-title v-else><v-btn :disabled="enableback" @click="querydirectories(parentdirectory)" icon small text class="mr-2"><v-icon >keyboard_backspace</v-icon></v-btn> ย้ายไฟล์ {{ filedata.file_name }}</v-card-title>-->
        <v-divider></v-divider>
        <!-- <v-subheader>{{ moveto }}</v-subheader> -->
        <v-card-text style="height: 400px;">
          <v-tabs v-model="tab" outlined>
             <!-- <v-tab
              @click="
                (checktab = 1), querydirectories(parentfolder, parentfolder)
              "
              >ไดรฟ์ของฉัน</v-tab
            >
            <v-tab
              @click="(checktab = 2), fn_getlist()"
              v-if="dataAccountActive.type === 'Citizen'"
              >ไดร์ฟบริษัท</v-tab
            > -->
            <v-tab
              @click="
                (checktab = 1)"
              :disabled="createprogress"
              >{{dataAccountActive.type !== 'Citizen' ? $t("toolbar.mydrive_bu") : $t("toolbar.mydrive")}}</v-tab
            >
            <v-tab
            @click="(checktab = 2)"
              v-if="dataAccountActive.type === 'Citizen'"
              :disabled="createprogress"
              >{{$t("toolbar.mydrive_bu")}}</v-tab
            >
            <v-tab-item>
              <v-layout row wrap class="pa-4">
                <v-flex v-if="createprogress === false">
                  <v-list nav dense v-if="listdirectory.length > 0">
                    <!-- <v-subheader > -->
                    <v-layout row wrap>
                      <!-- <v-flex lg1 sm1 xs2>
        
              </v-flex> -->
                      <v-flex lg12 sm12 xs10 class="mt-1">
                        <v-chip :color="color.theme" text-color="white">
                          <!-- <v-btn
                  :disabled="enableback"
                  @click="querydirectories(parentdirectory)"
                  x-small
                  fab
                >
                <v-icon>keyboard_backspace</v-icon>
                </v-btn> -->
                          <!-- <v-avatar
                            :disabled="enableback"
                            @click="querydirectories(parentdirectory)"
                            style="cursor: pointer"
                          >
                            <v-icon>mdi-arrow-left-circle</v-icon>
                          </v-avatar> -->
                          <v-btn
                            :disabled="enableback"
                            @click="querydirectories(parentdirectory)"
                            style="cursor: pointer; background-color: transparent !important;"
                            icon
                            color="white"
                            class="ml-n1 mr-n2"
                          >
                            <v-icon>mdi-arrow-left-circle</v-icon>
                          </v-btn>
                          <!-- <v-avatar left>
                    <v-icon>mdi-folder-move</v-icon>
                  </v-avatar> -->
                          <span class="ml-4" v-if="resolutionScreen >= 400">
                            {{ parentname }}</span
                          >
                          <span class="ml-4" v-else>{{
                            parentname.length >= 30
                              ? parentname.substring(0, 30) + "..."
                              : parentname
                          }}</span>
                        </v-chip>
                      </v-flex>
                    </v-layout>
                    <br />
                    <!-- </v-subheader> -->
                    <v-list-item-group v-model="item" :color="color.theme" @change="clicker">
                      <!-- <v-list-item-group v-model="item" :color="color.theme"> -->
                      <!-- {{listdirectory}} -->
                      <!-- //! ของเดิมใช้ folder_id -->
                      <!-- //? ใช้ @click bind moveto กับ id -->
                      <!-- {{ moveto }} -->
                      <v-list-item
                        v-for="(item, i) in listdirectory"
                        :key="i"
                        :disabled="fn_permission_folder(item.permission_account_v2)"
                        @dblclick="querydirectories(item.folder_id)"
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="
                              item.folder_status === 'M' ? 'green' : '#ffc107'
                            "
                            :disabled="fn_permission_folder(item.permission_account_v2)"
                            large
                            >folder</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content>
                          <!-- //! ของเดิมใช้ folder_name -->
                          <v-list-item-title>{{
                            item.folder_name
                          }}</v-list-item-title>
                        </v-list-item-content>
                        <!-- Add new permission chip -->
                        <v-list-item-action
                          v-if="
                            fn_permission_folder(item.permission_account_v2)
                          "
                        >
                          <v-chip x-small class="py-2">
                            {{ $t("movefiles.permission_denied") }}
                          </v-chip>
                        </v-list-item-action>
                        <!-- ////// -->
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-list nav dense v-else>
                    <v-layout row wrap>
                      <!-- <v-flex lg1 sm1 xs2>
                <v-btn
                  :disabled="enableback"
                  @click="querydirectories(parentdirectory)"
                  small
                  fab
                  :color="color.theme"
                >
                  <v-icon :color="color.chipText">keyboard_backspace</v-icon>
                </v-btn>
              </v-flex> -->
                      <v-flex lg12 sm12 xs10 class="mt-1">
                        <!-- <v-subheader class="pl-0"> -->
                        <v-chip :color="color.theme" text-color="white">
                          <!-- <v-avatar left>
                    <v-icon>mdi-folder-move</v-icon>
                  </v-avatar> -->
                          <!-- <v-avatar
                            :disabled="enableback"
                            @click="querydirectories(parentdirectory)"
                            style="cursor: pointer"
                          >
                            <v-icon>mdi-arrow-left-circle</v-icon>
                          </v-avatar> -->
                          <v-btn
                            :disabled="enableback"
                            @click="querydirectories(parentdirectory)"
                            style="cursor: pointer; background-color: transparent !important;"
                            icon
                            color="white"
                            class="ml-n1 mr-n2"
                          >
                            <v-icon>mdi-arrow-left-circle</v-icon>
                          </v-btn>
                          <span class="ml-4"> {{ parentname }}</span>
                        </v-chip>
                        <!-- </v-subheader> -->
                      </v-flex>
                    </v-layout>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-center">{{
                          $t("movefiles.nofolder")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex v-else>
                  <!-- <v-card-text style="height: 400px;" class="text-center" v-else> -->
                  <v-layout justify-center>
                    <br />
                    <v-progress-circular
                      class="mt-6 mb-6 pt-6 pb-6"
                      :size="50"
                      :dark="color.darkTheme"
                      :color="color.theme"
                      indeterminate
                    ></v-progress-circular>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-tab-item>
            <v-tab-item v-if="dataAccountActive.type === 'Citizen'">
              <v-layout row wrap class="pa-4">
                <v-flex v-if="createprogress === false">
                  <v-list nav dense v-if="listbusiness.length > 0">
                    <v-layout row wrap>
                      <v-flex lg12 sm12 xs10 class="mt-1">
                        <v-chip :color="color.theme" text-color="white">
                         <!-- <v-avatar
                            :disabled="enableback"
                            @click="
                              searchchildfolder_back(
                                backto_folder,
                                account_active_inbiz
                              )
                            "
                          >
                            <v-icon>mdi-arrow-left-circle</v-icon>
                          </v-avatar> -->
                          <v-btn
                            :disabled="enableback"
                            @click="
                              searchchildfolder_back(
                                backto_folder,
                                account_active_inbiz
                              )
                            "
                            style="cursor: pointer; background-color: transparent !important;"
                            icon
                            color="white"
                            class="ml-n1 mr-n2"
                          >
                            <v-icon>mdi-arrow-left-circle</v-icon>
                          </v-btn>
                          <span class="ml-4" v-if="resolutionScreen >= 400">{{
                            parentname_biz
                          }}</span>
                          <span class="ml-4" v-else>{{
                            parentname_biz.length >= 30
                              ? parentname_biz.substring(0, 30) + "..."
                              : parentname_biz
                          }}</span>
                        </v-chip>
                      </v-flex>
                    </v-layout>
                    <br />
                    <!-- <v-list-item-group v-model="item_" :color="color.theme"> -->
                      <v-list-item-group v-model="item_" :color="color.theme" @change="clickerBusiness">
                      <v-list-item
                        v-for="(item_, i) in listbusiness"
                        :key="i"
                        :disabled="
                              fn_permission_folder(item_.permission_account_v2)
                        "
                        @dblclick="
                          querydirectories(
                            item_.folder_id === '' ||
                              item_.folder_id === '' ||
                              item_.folder_id === undefined
                              ? this.moveto
                              : item_.folder_id,
                            item_
                          ), status_get_business = false
                        "
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="
                              item_.folder_status === 'M' ? 'green' : '#ffc107'
                            "
                            :disabled="
                              fn_permission_folder(item_.permission_account_v2)
                            "
                            large
                            >folder</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content>
                          <!-- this.$t('default') === 'th' -->
                          <v-list-item-title>{{
                            item_.folder_name
                          }}</v-list-item-title>
                        </v-list-item-content>
                         <!-- Add new permission chip -->
                        <v-list-item-action
                          v-if="
                            fn_permission_folder(item_.permission_account_v2)
                          "
                        >
                          <v-chip x-small class="py-2">
                            {{ $t("movefiles.permission_denied") }}
                          </v-chip>
                        </v-list-item-action>
                        <!-- //// -->
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-list nav dense v-else>
                    <v-layout row wrap>
                      <v-flex lg12 sm12 xs10 class="mt-1">
                        <v-chip :color="color.theme" text-color="white">
                          <!-- <v-avatar
                            :disabled="enableback"
                            @click="
                              searchchildfolder_back(
                                backto_folder,
                                account_active_inbiz
                              )
                            "
                          >
                            <v-icon>mdi-arrow-left-circle</v-icon>
                          </v-avatar> -->
                          <v-btn
                            :disabled="enableback"
                            @click="
                              searchchildfolder_back(
                                backto_folder,
                                account_active_inbiz
                              )
                            "
                            style="cursor: pointer; background-color: transparent !important;"
                            icon
                            color="white"
                            class="ml-n1 mr-n2"
                          >
                            <v-icon>mdi-arrow-left-circle</v-icon>
                          </v-btn>
                          <span class="ml-4"> {{ parentname_biz }}</span>
                        </v-chip>
                      </v-flex>
                    </v-layout>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-center">{{
                          $t("movefiles.nofolder")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex v-else>
                  <v-layout justify-center>
                    <br />
                    <v-progress-circular
                      class="mt-6 mb-6 pt-6 pb-6"
                      :size="50"
                      :dark="color.darkTheme"
                      :color="color.theme"
                      indeterminate
                    ></v-progress-circular>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="$emit('closedlmovefile'), cleardata(), $emit('openDrag')"
            :disabled="createprogress"
            >{{ $t("movefiles.buttonclose") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            @click="check_summitmove(), $emit('openDrag')"
            :loading="createprogress"
            :disabled="disablemove"
            class="white--text"
          >
          {{ wordingbutton }}
            <!-- {{
              this.item === "" ? $t("movefiles.maveat") : $t("movefiles.move")
            }} -->
          </v-btn>
          <!-- :dark="color.darkTheme" -->
        </v-card-actions>
      </v-card>

      <!-- ขนาดหน้าจอโทรศัพท์ -->
      <!-- <v-card v-else>
        <v-card-title>
          <v-layout row wrap>
            <v-flex lg11 sm11 xs10 class="pl-2">
              <v-list-item-title style="font-size: 15px;"
                >{{
                  filedata.file_type === "folder"
                    ? $t("movefiles.movefolder")
                    : $t("movefiles.movefiles")
                }}
                {{ filedata.file_name }}</v-list-item-title
              >
            </v-flex>
            <v-flex lg1 sm1 xs2>
              <v-icon
                class="text-right ml-5"
                :color="color.theme"
                dense
                dark
                size="28"
                >mdi-folder-swap</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 400px;">
          <v-layout row wrap class="pa-4">
            <v-flex v-if="createprogress === false">
              <v-list nav dense v-if="listdirectory.length > 0">
                <v-layout row wrap>
                  <v-flex lg1 sm1 xs2>
                    <v-btn
                      :disabled="enableback"
                      @click="querydirectories(parentdirectory,parentfolder)"
                      small
                      fab
                      :color="color.theme"
                    >
                      <v-icon :color="color.chipText"
                        >keyboard_backspace</v-icon
                      >
                    </v-btn>
                  </v-flex>
                  <v-flex lg11 sm11 xs10 class="mt-1">
                    <v-chip
                      :color="color.theme"
                      text-color="white"
                      style="font-size: 13px;"
                    >
                      <v-avatar left>
                        <v-icon>mdi-folder-move</v-icon>
                      </v-avatar>
                      <span v-if="resolutionScreen >= 400">
                        {{ parentname }}</span
                      >
                      <span v-else>{{
                        parentname.length >= 30
                          ? parentname.substring(0, 30) + "..."
                          : parentname
                      }}</span>
                    </v-chip>
                  </v-flex>
                </v-layout>
                <br />
                <v-list-item-group v-model="item" :color="color.theme">
                  <v-list-item
                    v-for="(item, i) in listdirectory"
                    :key="i"
                    @dblclick="querydirectories(item.folder_id,item)"
                  >
                    <v-list-item-icon>
                      <v-icon large>folder</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.folder_name
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-list nav dense v-else>
                <v-layout row wrap>
                  <v-flex lg1 sm1 xs2>
                    <v-btn
                      :disabled="enableback"
                      @click="querydirectories(parentdirectory,parentfolder)"
                      small
                      fab
                      :color="color.theme"
                    >
                      <v-icon :color="color.chipText"
                        >keyboard_backspace</v-icon
                      >
                    </v-btn>
                  </v-flex>
                  <v-flex lg11 sm11 xs10 class="mt-1">
                    <v-chip :color="color.theme" text-color="white">
                      <v-avatar left>
                        <v-icon>mdi-folder-move</v-icon>
                      </v-avatar>
                      {{ parentname }}
                    </v-chip>
                  </v-flex>
                </v-layout>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-center">{{
                      $t("movefiles.nofolder")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex v-else>
              <v-layout justify-center>
                <br />
                <v-progress-circular
                  class="mt-6 mb-6 pt-6 pb-6"
                  :size="50"
                  :dark="color.darkTheme"
                  :color="color.theme"
                  indeterminate
                ></v-progress-circular>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="padding:12px">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="red"
            outlined
            @click="$emit('closedlmovefile'), cleardata(), $emit('openDrag')"
            :disabled="createprogress"
            >{{ $t("movefiles.buttonclose") }}</v-btn
          >
          <v-btn
            small
            :color="color.theme"
            :dark="color.darkTheme"
            @click="summitmovefile(), $emit('openDrag')"
            :loading="createprogress"
          >
            {{
              this.item === "" ? $t("movefiles.maveat") : $t("movefiles.move")
            }}
          </v-btn>
        </v-card-actions>
      </v-card> -->
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-tabs v-model="tab" outlined>
          <!-- <v-tab
            @click="
              (checktab = 1), querydirectories(parentfolder, parentfolder)
            "
            >ไดรฟ์ของฉัน</v-tab
          >
          <v-tab
            @click="(checktab = 2), fn_getlist()"
            v-if="dataAccountActive.type === 'Citizen'"
            >ไดร์ฟบริษัท</v-tab
          > -->
          <v-tab
            @click="
              (checktab = 1)"
            >{{ dataAccountActive.type !== 'Citizen' ? $t("toolbar.mydrive_bu") : $t("toolbar.mydrive") }}</v-tab
          >
          <v-tab
            @click="(checktab = 2)"
            v-if="dataAccountActive.type === 'Citizen'"
            >{{ $t("toolbar.mydrive_bu") }}</v-tab
          >
          <v-tab-item>
            <v-card-text class="pa-5">
              <v-layout class="pt-2 pb-4">
                <v-flex d-flex justify-center class="ml-8">
                  <span :style="headerPage">{{
                    filedata.file_type === "folder"
                      ? $t("movefiles.movefolder")
                      : $t("movefiles.movefiles")
                  }}</span>
                </v-flex>
                <v-flex xs1 d-flex justify-end>
                  <v-icon
                    :color="$vuetify.theme.dark ? 'white' : color.theme"
                    @click="
                      $emit('closedlmovefile'), cleardata(), $emit('openDrag')
                    "
                    >mdi-close</v-icon
                  >
                </v-flex>
              </v-layout>
              <v-chip
                v-if="createprogress === false"
                :style="headerChipPage"
                :color="$vuetify.theme.dark ? '#989898' : color.theme"
              >
                <!-- <v-list-item-avatar
                  :disabled="enableback"
                  @click="querydirectories(parentdirectory)"
                  class="text-center"
                >
                  <v-icon
                    :color="$vuetify.theme.dark ? 'white' : 'white'"
                    dark
                    size="25"
                    >mdi-arrow-left-circle</v-icon
                  >
                </v-list-item-avatar> -->
                <v-btn
                  :disabled="enableback"
                  @click="querydirectories(parentdirectory)"
                  style="cursor: pointer; background-color: transparent !important;"
                  icon
                  color="white"
                  class="ml-n1 mr-3"
                >
                  <v-icon
                    :color="$vuetify.theme.dark ? 'white' : 'white'"
                    dark
                    size="25"
                    >mdi-arrow-left-circle</v-icon
                  >
                </v-btn>
                <v-list-item-title class="ml-n2">
                  <span
                    :style="headerPageMobile"
                    v-if="resolutionScreen >= 400"
                  >
                    {{ parentname }}</span
                  >
                  <span :style="headerPageMobile" v-else>{{
                    parentname.length >= 30
                      ? parentname.substring(0, 30) + "..."
                      : parentname
                  }}</span>
                </v-list-item-title>
              </v-chip>
            </v-card-text>
            <v-card-text style="height: 400px; overflow-y: scroll; ">
              <v-layout row wrap>
                <v-flex v-if="createprogress === false">
                  <v-list nav dense v-if="listdirectory.length > 0">
                    <!-- <v-list-item-group v-model="item" :color="color.theme"> -->
                      <v-list-item-group v-model="item" :color="color.theme" @change="clicker">
                      <v-list-item
                        v-for="(item, i) in listdirectory"
                        :key="i"
                        :disabled="
                          fn_permission_folder(item.permission_account_v2)
                        "
                        @dblclick="querydirectories(item.folder_id)"
                      >
                        <v-list-item-icon>
                          <v-icon 
                          :disabled="
                              fn_permission_folder(item.permission_account_v2)
                            "
                          :color=" item.folder_status === 'M' ? 'green' : '#ffc107'" large>folder</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.folder_name
                          }}</v-list-item-title>
                        </v-list-item-content>
                         <!-- Add new permission chip -->
                        <v-list-item-action
                          v-if="
                            fn_permission_folder(item.permission_account_v2)
                          "
                        >
                          <v-chip x-small class="py-2">
                            {{ $t("movefiles.permission_denied") }}
                          </v-chip>
                        </v-list-item-action>
                        <!-- //// -->
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-list nav dense v-else>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-center">{{
                          $t("movefiles.nofolder")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex v-else>
                  <v-layout justify-center>
                    <br />
                    <v-progress-circular
                      class="mt-6 mb-6 pt-6 pb-6"
                      :size="50"
                      :dark="color.darkTheme"
                      :color="color.theme"
                      indeterminate
                    ></v-progress-circular>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-tab-item>
          <v-tab-item v-if="dataAccountActive.type === 'Citizen'">
            <v-card-text class="pa-5">
              <v-layout class="pt-2 pb-4">
                <v-flex d-flex justify-center class="ml-8">
                  <span :style="headerPage">{{
                    filedata.file_type === "folder"
                      ? $t("movefiles.movefolder")
                      : $t("movefiles.movefiles")
                  }}</span>
                </v-flex>
                <v-flex xs1 d-flex justify-end>
                  <v-icon
                    :color="$vuetify.theme.dark ? 'white' : color.theme"
                    @click="
                      $emit('closedlmovefile'), cleardata(), $emit('openDrag')
                    "
                    >mdi-close</v-icon
                  >
                </v-flex>
              </v-layout>
              <v-chip
                v-if="createprogress === false"
                :style="headerChipPage"
                :color="$vuetify.theme.dark ? '#989898' : color.theme"
              >
                <!-- <v-list-item-avatar
                  :disabled="enableback"
                  @click="
                    searchchildfolder_back(backto_folder, account_active_inbiz)
                  "
                  class="text-center"
                >
                  <v-icon
                    :color="$vuetify.theme.dark ? 'white' : 'white'"
                    dark
                    size="25"
                    >mdi-arrow-left-circle</v-icon
                  >
                </v-list-item-avatar> -->
                <v-btn
                  :disabled="enableback"
                  @click="
                    searchchildfolder_back(
                      backto_folder,
                      account_active_inbiz
                    )
                  "
                  style="cursor: pointer; background-color: transparent !important;"
                  icon
                  color="white"
                  class="ml-n1 mr-3"
                >
                  <v-icon
                    :color="$vuetify.theme.dark ? 'white' : 'white'"
                    dark
                    size="25"
                    >mdi-arrow-left-circle</v-icon
                  >
                </v-btn>
                <v-list-item-title class="ml-n2">
                  <span
                    :style="headerPageMobile"
                    v-if="resolutionScreen >= 400"
                  >
                    {{ parentname_biz }}</span
                  >
                  <span :style="headerPageMobile" v-else>{{
                    parentname_biz.length >= 30
                      ? parentname_biz.substring(0, 30) + "..."
                      : parentname_biz
                  }}</span>
                </v-list-item-title>
              </v-chip>
            </v-card-text>
            <v-card-text style="height: 400px; overflow-y: scroll;">
              <v-layout row wrap>
                <v-flex v-if="createprogress === false">
                  <v-list nav dense v-if="listbusiness.length > 0">
                    <!-- <v-list-item-group v-model="item_" :color="color.theme"> -->
                      <v-list-item-group v-model="item_" :color="color.theme" @change="clickerBusiness">
                      <v-list-item
                        v-for="(item_, i) in listbusiness"
                        :key="i"
                        :disabled="
                          fn_permission_folder(item_.permission_account_v2)
                        "
                        @dblclick="
                          querydirectories(item_.folder_id,item_), status_get_business = false
                        "
                      >
                        <v-list-item-icon>
                          <v-icon 
                          :disabled="
                              fn_permission_folder(item_.permission_account_v2)
                            "
                          :color=" item_.folder_status === 'M' ? 'green' : '#ffc107'" large>folder</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item_.folder_name
                          }}</v-list-item-title>
                        </v-list-item-content>
                         <!-- Add new permission chip -->
                        <v-list-item-action
                          v-if="
                            fn_permission_folder(item.permission_account_v2)
                          "
                        >
                          <v-chip x-small class="py-2">
                            {{ $t("movefiles.permission_denied") }}
                          </v-chip>
                        </v-list-item-action>
                        <!-- //// -->
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-list nav dense v-else>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-center">{{
                          $t("movefiles.nofolder")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex v-else>
                  <v-layout justify-center>
                    <br />
                    <v-progress-circular
                      class="mt-6 mb-6 pt-6 pb-6"
                      :size="50"
                      :dark="color.darkTheme"
                      :color="color.theme"
                      indeterminate
                    ></v-progress-circular>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-tab-item>
        </v-tabs>
        <div class="text-center my-4">
          <v-btn
            :style="btnAction"
            class="mr-2"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="$emit('closedlmovefile'), cleardata(), $emit('openDrag')"
            :disabled="createprogress"
            >{{ $t("movefiles.buttonclose") }}</v-btn
          >
          <v-btn
          class="elevation-0 white--text"
            :style="btnAction"
            height="40px"
            width="40%"
            :color="color.theme"
            @click="check_summitmove(), $emit('openDrag')"
            :loading="createprogress"
            :disabled="disablemove"
          >
            {{ wordingbutton }}
            <!-- {{
              this.item === "" ? $t("movefiles.maveat") : $t("movefiles.move")
            }} -->
          </v-btn>
          <!-- :dark="color.darkTheme" -->
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { log } from "console";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {},
  props: ["show", "parentfolder", "filedata", "parentfoldername"],
  data: function() {
    return {
      enableback: false,
      createprogress: false,
      listdirectory: [],
      parentdirectory: "",
      parentname: "",
      parentname_biz: "",
      item: "",
      item_: "",
      moveto: "",
      listbusiness: [],
      tab: 0,
      checktab: 1,
      search: "",
      folderid: "",
      backto_folder: "",
      account_active_inbiz: "",
      full_path: "",
      first_opendialog: true,
      wordingbutton: this.$t("movefiles.maveat"),
      business_name: "",
      disablemove: false,
      status_get_business: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          let filename = this.filedata.file_name.split(".");
          let i;
          let namewithoutextension = "";
          for (i = 0; i < filename.length; i++) {
            if (i !== filename.length - 1) {
              namewithoutextension += filename[i];
            } else if (filename.length - 1 === 0) {
              namewithoutextension += filename[i];
            }
          }
          this.filename = namewithoutextension;
          if (this.grant_move_file === true) {
            if (this.first_opendialog === true) {
              this.first_opendialog = false;
              if (this.checktab === 1) {
                this.querydirectories(this.parentfolder, this.parentfolder);
              } else {
                this.fn_getlist();
              }
            }
            this.$emit("closeDrag");
            return this.show;
          } else {
            this.fn_permission_denied();
            return false;
          }
        }
      },
      set(value) {
        if (!value) {
          this.first_opendialog = true;
          this.checktab = 1;
          this.tab = 0;
          this.$emit("closedbmovefile");
          this.$emit("openDrag");
        }
      },
    },
    grant_move_file: {
      get() {
        return (
          this.filedata.file_permission_2 === "04" ||
          this.filedata.file_permission_2 === "05" ||
          this.filedata.file_permission_2 === "06"
        );
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษรใน chip ของ mobile
    headerPageMobile() {
      return "color:white;" + "font-size: 16px; line-height: 24px;";
    },
    // style chip ของ mobile
    headerChipPage() {
      return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
  watch: {
    tab: function (val) {
      if(val === 0){
        this.querydirectories(this.parentfolder, this.parentfolder);
      } else {
        this.fn_getlist();
      }
    }
  },
  methods: {
    async fn_getlist() {
      this.createprogress = true;
      this.disablemove = true;
      this.status_get_business = true
      this.enableback = true;
      this.listbusiness = [];
      let payload = {
        user_id: this.dataUsername,
      };
      console.log("payload searchdirectory", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/get_biz_for_move_data",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            // console.log("response",response.data.result);
            for (let i = 0; i < response.data.result.length; i++) {
              let datalistbusines = {};
              datalistbusines["account_id"] =
                response.data.result[i]["account_id"];
              datalistbusines["business_id"] =
                response.data.result[i]["business_id"];
              datalistbusines["first_name_eng"] =
                response.data.result[i]["first_name_eng"];
              datalistbusines["folder_name"] =
                response.data.result[i]["first_name_th"];
              datalistbusines["folder_id"] =
                response.data.result[i]["my_folder_id"];
              this.listbusiness.push(datalistbusines);
            }
            console.log(" this.listbusiness", this.listbusiness);
            // this.parentname_biz = "บริษัท";
            this.parentname_biz = this.$t("admin.business");
            this.createprogress = false;
          } else {
            this.listbusiness = [];
            this.createprogress = false;
            console.log("");
          }
          this.item_ = "";
          this.clickerBusiness();
        })
        .catch((err) => {
          this.listbusiness = [];
          this.createprogress = false;
          console.log(err);
        });
    },
    //  Add new permission
    fn_permission_folder(permission_text) {
      //console.log("permission text ", permission_text)
      if (permission_text !== undefined) {
        let permission_number = permission_text.split("")[1];
        if (permission_number < 3) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    fn_permission_denied() {
      Toast.fire({
        icon: "error",
        title: this.$t("multimovefiles.permission_denied"),
      });
      this.$emit("closedbmovefile");
      this.$emit("openDrag");
    },
    clicker() {
      if (this.item === undefined || this.item === "") {
        this.wordingbutton = this.$t("movefiles.maveat");
      } else {
        this.wordingbutton = this.$t("movefiles.move");
      }
    },

    clickerBusiness() {
      if (this.item_ === undefined || this.item_ === "") {
        this.wordingbutton = this.$t("movefiles.maveat");
        if (this.status_get_business === true) {
          this.disablemove = true;
        }
      } else {
        this.wordingbutton = this.$t("movefiles.move");
        this.disablemove = false;
      }
    },

    cleardata() {
      this.createprogress = false;
      this.listdirectory = [];
      this.parentdirectory = "";
      this.parentname = "";
      this.moveto = "";
      this.item = "";
      this.listbusiness = [];
      this.tab = 0;
      this.checktab = 1;
      this.item = "";
      this.item_ = "";
      this.backto_folder = "";
      this.account_active_inbiz = "";
      this.first_opendialog = true;
      this.business_name = "";
    },
    close() {},
    check_summitmove() {
      console.log("this.checktab", this.checktab);
      if (this.checktab === 1) {
        console.log("เข้า summit 1");
        this.summitmovefile();
      } else {
        console.log("เข้า summit 2");
        this.summitmovefile2();
      }
    },
    summitmovefile() {
      var payload;
      console.log("item", this.item);
      if (this.item === undefined || this.item === null || this.item === "") {
        if (this.filedata.file_type === "folder") {
          console.log(1);
          payload = {
            data_id: this.filedata.file_id,
            data_type: "folder",
            account_id: this.dataAccountId,
            folder_id_move_to: this.moveto,
            // account_id: this.dataAccountId,
            // folder_id: this.filedata.file_id,
            // move_to_id: this.moveto,
            // user_id: this.dataUsername,
          };
          this.querymovefolder(payload);
        } else {
          console.log(2);
          payload = {
            data_id: this.filedata.file_id,
            data_type: "file",
            account_id: this.dataAccountId,
            folder_id_move_to: this.moveto,
            // account_id: this.dataAccountId,
            // // ! ของเก่า folder_id
            // move_to_id: this.moveto,
            // file_id: this.filedata.file_id,
            // user_id: this.dataUsername,
          };
          this.querymovefile(payload);
        }
      } else {
        if (this.filedata.file_type === "folder") {
          console.log(3);
          payload = {
            data_id: this.filedata.file_id,
            data_type: "folder",
            account_id: this.dataAccountId,
            // folder_id_move_to: this.listdirectory[this.item]["folder_id"],
            folder_id_move_to: this.listdirectory[this.item] !== undefined ? this.listdirectory[this.item]["folder_id"] : this.moveto,

            // account_id: this.dataAccountId,
            // folder_id: this.filedata.file_id,
            // // ! ของเก่า folder_id
            // move_to_id: this.listdirectory[this.item]["folder_id"],
            // user_id: this.dataUsername,
          };
          this.querymovefolder(payload);
        } else {
          console.log(4);
          payload = {
            data_id: this.filedata.file_id,
            data_type: "file",
            account_id: this.dataAccountId,
             // folder_id_move_to: this.listdirectory[this.item]["folder_id"],
             folder_id_move_to: this.listdirectory[this.item] !== undefined ? this.listdirectory[this.item]["folder_id"] : this.moveto,

            // account_id: this.dataAccountId,
            // // ! ของเก่า folder_id
            // move_to_id: this.listdirectory[this.item]["folder_id"],
            // file_id: this.filedata.file_id,
            // user_id: this.dataUsername,
          };
          this.querymovefile(payload);
        }
      }
      console.log("payload", payload);
      console.log("folder");
    },
    async querymovefile(payload) {
      this.createprogress = true;
      let auth = await gbfGenerate.generateToken();
      this.axios
        // .post(process.env.VUE_APP_SERVICE_UPDATE_FILE + "/api/update_file", payload, { headers: { Authorization: auth.code } })
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/move/data",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          this.createprogress = false;
          console.log(response.data);
          if (response.data.status === "OK") {
            this.$emit("updatedatafile");
            Toast.fire({
              icon: "success",
              title:
                this.$t("movefiles.movefiles") +
                " " +
                this.filedata.file_name +
                " " +
                this.$t("movefiles.success"),
              // title: "ย้ายไฟล์ " + this.filedata.file_name + " สำเร็จ"
            });
          } else if (response.data.errorCode === "ER405") {
            Toast.fire({
              icon: "warning",
              title: this.$t("movefiles.filenomove"),
            });
          } else if (response.data.errorCode === "ER500") {
            Toast.fire({
              icon: "warning",
              title: this.$t("movefiles.choosetargetfolder"),
            });
          } else {
            console.log(response);
            Toast.fire({
              icon: "warning",
              title: response.data.errorMessage,
              // title: this.$t("movefiles.filenomove"),
              // title: this.$t('movefiles.movefiles') +" "+this.filedata.file_name +" "+this.$t('movefiles.nosuccess')
              // title: "ย้ายไฟล์" + this.filedata.file_name + " ไม่สำเร็จ"
            });
          }
        })
        .catch((error) => {
          this.createprogress = false;
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("movefiles.cannotmovefile"),
          });
        });
    },
    async querymovefolder(payload) {
      this.createprogress = true;
      let auth = await gbfGenerate.generateToken();
      this.axios
        // .post(process.env.VUE_APP_SERVICE_UPDATE_FOLDER + "/api/update_folder_byid", payload, {
        //   headers: { Authorization: auth.code },
        // })
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/move/data",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          this.createprogress = false;
          console.log(response.data);
          if (response.data.status === "OK") {
            this.$emit("updatedatafile");
            Toast.fire({
              icon: "success",
              title:
                this.$t("movefiles.movefolder") +
                " " +
                this.filedata.file_name +
                " " +
                this.$t("movefiles.success"),
              // title: "ย้ายโฟลเดอร์ " + this.filedata.file_name + " สำเร็จ"
            });
          } else {
            Toast.fire({
              icon: "warning",
              title: this.$t("movefiles.foldernomove"),
              // title: response.data.errorMessage
            });
          }
        })
        .catch((error) => {
          this.createprogress = false;
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("movefiles.cannotmovefolder"),
          });
        });
    },

    summitmovefile2() {
      var payload;
      console.log("item_", this.item_);
      if (
        this.item_ === undefined ||
        this.item_ === null ||
        this.item_ === ""
      ) {
        if (this.filedata.file_type === "folder") {
          console.log(1);
          payload = {
            data_id: this.filedata.file_id,
            data_type: "folder",
            account_id: this.dataAccountId,
            // account_id: this.listbusiness[this.item_]["account_id"],
            folder_id_move_to: this.moveto,
            // user_id:this.listbusiness[this.item_]["account_id"]
            user_id: this.dataUsername,
          };
          this.querymovefolder2(payload);
        } else {
          console.log(2);
          payload = {
            data_id: this.filedata.file_id,
            data_type: "file",
            account_id: this.dataAccountId,
            // account_id:this.listbusiness[this.item_]["account_id"],
            folder_id_move_to: this.moveto,
            // user_id:this.listbusiness[this.item_]["account_id"]
            user_id: this.dataUsername,
          };
          this.querymovefile2(payload);
        }
      } else {
        if (this.filedata.file_type === "folder") {
          console.log(3);
          payload = {
            data_id: this.filedata.file_id,
            data_type: "folder",
            account_id: this.dataAccountId,
            // account_id: this.listbusiness[this.item_]["account_id"],
            // folder_id_move_to: this.listbusiness[this.item_]["folder_id"],
            folder_id_move_to: this.listbusiness[this.item_] !== undefined ? this.listbusiness[this.item_]["folder_id"] : this.moveto,
            user_id: this.dataUsername,
            // user_id:this.listbusiness[this.item_]["account_id"]
          };
          this.querymovefolder2(payload);
        } else {
          console.log(4);
          payload = {
            data_id: this.filedata.file_id,
            data_type: "file",
            account_id: this.dataAccountId,
            // account_id: this.listbusiness[this.item_]["account_id"],
           // folder_id_move_to: this.listbusiness[this.item_]["folder_id"],
           folder_id_move_to: this.listbusiness[this.item_] !== undefined ? this.listbusiness[this.item_]["folder_id"] : this.moveto,
            user_id: this.dataUsername,
            // user_id:this.listbusiness[this.item_]["account_id"]
          };
          this.querymovefile2(payload);
        }
      }
      console.log("payload", payload);
      console.log("folder");
    },
    async querymovefile2(payload) {
      this.createprogress = true;
      let auth = await gbfGenerate.generateToken();
      this.axios
        // .post(process.env.VUE_APP_SERVICE_UPDATE_FILE + "/api/update_file", payload, { headers: { Authorization: auth.code } })
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/move/data_citizen_to_business",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          this.createprogress = false;
          console.log(response.data);
          if (response.data.status === "OK") {
            this.$emit("updatedatafile");
            Toast.fire({
              icon: "success",
              title:
                this.$t("movefiles.movefiles") +
                " " +
                this.filedata.file_name +
                " " +
                this.$t("movefiles.success"),
              // title: "ย้ายไฟล์ " + this.filedata.file_name + " สำเร็จ"
            });
          } else if (response.data.errorCode === "ER500") {
            Toast.fire({
              icon: "warning",
              title: this.$t("movefiles.choosetargetfolder"),
            });
          } else {
            console.log(response);
            Toast.fire({
              icon: "warning",
              title: response.data.errorMessage,
              // title: this.$t("movefiles.filenomove"),
              // title: this.$t('movefiles.movefiles') +" "+this.filedata.file_name +" "+this.$t('movefiles.nosuccess')
              // title: "ย้ายไฟล์" + this.filedata.file_name + " ไม่สำเร็จ"
            });
          }
        })
        .catch((error) => {
          this.createprogress = false;
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("movefiles.cannotmovefile"),
          });
        });
    },
    async querymovefolder2(payload) {
      this.createprogress = true;
      let auth = await gbfGenerate.generateToken();
      this.axios
        // .post(process.env.VUE_APP_SERVICE_UPDATE_FOLDER + "/api/update_folder_byid", payload, {
        //   headers: { Authorization: auth.code },
        // })
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/move/data_citizen_to_business",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          this.createprogress = false;
          console.log(response.data);
          if (response.data.status === "OK") {
            this.$emit("updatedatafile");
            Toast.fire({
              icon: "success",
              title:
                this.$t("movefiles.movefolder") +
                " " +
                this.filedata.file_name +
                " " +
                this.$t("movefiles.success"),
              // title: "ย้ายโฟลเดอร์ " + this.filedata.file_name + " สำเร็จ"
            });
          } else if (response.data.errorCode === "ER405") {
            Toast.fire({
              icon: "warning",
              title: this.$t("movefiles.foldernomove")
            });
          } else {
            Toast.fire({
              icon: "warning",
              title: response.data.errorMessage
            });
          }
        })
        .catch((error) => {
          this.createprogress = false;
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("movefiles.cannotmovefolder"),
          });
        });
    },

    querydirectories(parameter, item) {
      console.log("parameter", parameter);
      console.log("this.checktab", this.checktab);
      //ตรวจสอบสิทธิ์การย้าย
      if (this.grant_move_file === true) {
        this.createprogress = true;
        this.disablemove = false;
        this.enableback = false;
        // this.createprogress = false;
        setTimeout(() => {
          if (this.checktab === 1) {
            console.log("เข้า1");
            this.searchchildfolder(parameter);
          } else {
            console.log("เข้า2");
            if (this.parentname_biz === this.$t("admin.business")) {
              this.business_name = item.folder_name;
            }
            // if(this.parentname_biz === 'root'){
            //   this.fn_getlist();
            // }else{
              this.searchchildfolder_biz(parameter, item);
            // }
          }
        }, 500);
      } else {
        Toast.fire({
          icon: "error",
          title: this.$t("multimovefiles.permission_denied"),
        });
        this.$emit("closedbmovefile");
        this.$emit("openDrag");
      }
    },

    async searchchildfolder(parameter2) {
      this.listdirectory = [];
      this.targetfolder = "";
      let payload = {
        user_id: this.dataUsername,
        folder_id: parameter2,
        account_id: this.dataAccountId,
        status_folder: "",
      };
      console.log("payload searchdirectory", payload);
      let auth = await gbfGenerate.generateToken();
      let apiMoveFolder = this.dataAccountActive.type === 'Citizen' ?
      this.checktab === 2 ? '/api/v2/search_folder_byid_admin_ignore' : '/api/v2/search_folder_byid' : '/api/v2/search_folder_byid_admin_ignore'
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FOLDER +
          apiMoveFolder,
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            // this.targetfolder = Folder ID ที่ต้องการย้าย

            //กรณีที่ F
            if (
              response.data.your_folder[0]["folder_status"] === "R" &&
              this.dataAccountActive["type"] === "Business"
            ) {
              //เข้ามาใน Business Drives
              if (
                parameter2 ===
                this.dataAccountActive["business_info"]["my_folder_id"]
              ) {
                for (
                  let a = 0;
                  a < this.dataAccountActive["directory_business"].length;
                  a++
                ) {
                  let datafolder = {};
                  datafolder["folder_id"] = this.dataAccountActive[
                    "directory_business"
                  ][a]["department_folder_id"];
                  datafolder["folder_name"] = this.dataAccountActive[
                    "directory_business"
                  ][a]["department_name"];
                  datafolder["folder_status"] = "M";
                  datafolder["parent_folder_id"] = this.dataAccountActive[
                    "business_info"
                  ]["my_folder_id"];
                  datafolder["name_eng"] = this.dataAccountActive[
                    "business_info"
                  ]["first_name_eng"];
                  datafolder["name_th"] = this.dataAccountActive[
                    "business_info"
                  ]["first_name_th"];
                  datafolder["user_id"] = "";
                  this.listdirectory.push(datafolder);
                }
                this.parentdirectory = this.dataAccountActive["directory_id"];
                this.moveto = this.dataAccountActive["business_info"][
                  "my_folder_id"
                ];
                // this.enableback = false;
                this.enableback = true;
                this.parentname = this.$store.getters.dataTypeusercurrent(
                  this.$store.state.account_active["type"],
                  this.$t("default")
                );

                //อยุ่ใน Department Folder
              } else if (
                this.dataAccountActive["directory_business"].findIndex(
                  (t) => t.department_folder_id === parameter2
                ) >= 0
              ) {
                let index_data = this.dataAccountActive[
                  "directory_business"
                ].findIndex((t) => t.department_folder_id === parameter2);
                this.moveto = this.dataAccountActive["directory_business"][
                  index_data
                ]["department_folder_id"];
                this.parentdirectory = this.dataAccountActive["business_info"][
                  "my_folder_id"
                ];
                this.enableback = false;
                this.parentname = this.dataAccountActive["directory_business"][
                  index_data
                ]["department_name"];

                //อยุ่ใน Root Business
              } else {
                let datafolder = {};
                datafolder["folder_id"] = this.dataAccountActive[
                  "business_info"
                ]["my_folder_id"];
                datafolder[
                  "folder_name"
                ] = this.$store.getters.dataTypeusercurrent(
                  this.$store.state.account_active["type"],
                  this.$t("default")
                );
                datafolder["folder_status"] = "M";
                datafolder["parent_folder_id"] = this.dataAccountActive[
                  "directory_id"
                ];
                datafolder["name_eng"] = this.dataAccountActive[
                  "business_info"
                ]["first_name_eng"];
                datafolder["name_th"] = this.dataAccountActive["business_info"][
                  "first_name_th"
                ];
                datafolder["user_id"] = "";
                this.listdirectory.push(datafolder);

                this.moveto = this.dataAccountActive["directory_id"];
                this.parentdirectory = this.dataAccountActive["directory_id"];
                this.enableback = true;
                this.parentname = this.$t("toolbar.mydrive");
              }
            } else if (
              response.data.your_folder[0]["folder_status"] === "R" &&
              this.dataAccountActive["type"] === "Citizen"
            ) {
              this.moveto = this.dataAccountActive["directory_id"];
              this.parentdirectory = this.dataAccountActive["directory_id"];
              this.enableback = true;
              this.parentname = this.$t("toolbar.mydrive");
            } else {
              // ? ถ้าเป็น null ให้ไปที่อยู่ของโฟลเดอร์มัน
              this.moveto =
                response.data.your_folder[0]["folder_id"] ||
                this.dataAccountActive["directory_id"];
              // ! parent_folder_id
              this.parentdirectory =
                response.data.your_folder[0]["parent_folder_id"] === null
                  ? this.dataAccountActive["type"] === "Business"
                    ? this.dataAccountActive["business_info"]["my_folder_id"]
                    : this.dataAccountActive["directory_id"]
                  : response.data.your_folder[0]["parent_folder_id"];
              this.enableback = false;
              //! ของเดิมใช้ folder_name
              this.parentname = response.data.your_folder[0]["folder_name"];
            }

            let i;
            for (i = 0; i < response.data.folder.length; i++) {
              // console.log(this.filedata.file_id);
              // console.log(response.data.folder[i].folder_id);
              // ! folder_id
              if (this.filedata.file_id === response.data.folder[i].folder_id || response.data.folder[i]['folder_status'] === 'M') {
                continue;
              } else {
                this.listdirectory.push(response.data.folder[i]);
              }
              // console.log('res',response.data.folder[i])
              // let index = selectfolder.findIndex((a) => a === response.data.folder[i].folder_id);
              // if (index === -1) {
              //   console.log("index if myfile", index);
              // this.listdirectory.push(response.data.folder[i]);
              // } else {
              //   continue;
              //   console.log("index else", index);
              // }
            }
            this.item = "";
            this.clicker();
            this.createprogress = false;
          } else {
            this.listdirectory = [];
            this.createprogress = false;
            console.log("");
          }
        })
        .catch((err) => {
          this.listdirectory = [];
          this.createprogress = false;
          console.log(err);
        });
    },
    async searchchildfolder_biz(parameter2, item2) {
      this.listbusiness = [];
      this.targetfolder = "";
      this.full_path = "";
      let payload = {
        user_id: this.dataUsername,
        folder_id: parameter2,
        account_id: item2.account_id,
        status_folder: "",
      };
      console.log("payload searchdirectory", payload);
      let auth = await gbfGenerate.generateToken();
      let apiMoveFolder = this.dataAccountActive.type === 'Citizen' ?
      this.checktab === 2 ? '/api/v2/search_folder_byid_admin_ignore' : '/api/v2/search_folder_byid' : '/api/v2/search_folder_byid_admin_ignore'
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FOLDER +
          apiMoveFolder,
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            // this.targetfolder = Folder ID ที่ต้องการย้าย
            console.log("res", response.data);
            console.log(
              "response.data.folder.length",
              response.data.folder.length
            );
            if (response.data.folder.length > 0) {
              //กรณีที่ F

              if (response.data.your_folder[0]["folder_status"] === "R") {
                // this.moveto = this.dataAccountActive["directory_id"];
                // this.parentdirectory = this.dataAccountActive["directory_id"];
                // this.enableback = true;
                // this.parentname = this.$t("toolbar.mydrive");

                console.log("R&Citizen");
                this.moveto = response.data.your_folder[0]["folder_id"];
                // this.parentdirectory = response.data.folder[0]["account_id"];
                this.parentdirectory = response.data.your_folder[0]["account_id"];
                this.folderid = response.data.your_folder[0]["account_id"];
                // this.enableback = true;
                this.enableback = false;
                // this.backto_folder = response.data.your_folder[0]["folder_id"];
                this.backto_folder = response.data.your_folder[0]["parent_folder_id"];
                // this.parentname_biz =
                //     response.data.your_folder[0]["folder_name"];
                // ให้แสดงเป็นชื่อบริษัทที่เลือก
                this.parentname_biz = this.business_name
                this.account_active_inbiz =
                  response.data.your_folder[0]["account_id"];
                this.full_path = response.data.your_folder[0]["full_path"];
              } else {
                // ? ถ้าเป็น null ให้ไปที่อยู่ของโฟลเดอร์มัน
                this.moveto = response.data.your_folder[0]["folder_id"];
                // this.parentdirectory = response.data.folder[0]["account_id"];
                this.parentdirectory = response.data.your_folder[0]["account_id"];
                this.folderid = response.data.your_folder[0]["account_id"];
                this.enableback = false;
                // this.backto_folder = response.data.your_folder[0]["folder_id"];
                this.backto_folder = response.data.your_folder[0]["parent_folder_id"];
                //! ของเดิมใช้ folder_name
                this.parentname_biz =
                  response.data.your_folder[0]["folder_name"];
                this.account_active_inbiz =
                  response.data.your_folder[0]["account_id"];
                this.full_path = response.data.your_folder[0]["full_path"];
              }
              let i;
              for (i = 0; i < response.data.folder.length; i++) {
                this.listbusiness.push(response.data.folder[i]);
              }
            } else {
              console.log(this.listbusiness);
              // console.log(this.listbusiness[0].folder_id);
              console.log(response.data.your_folder[0]["full_path_id"]);

              let user_id_biz = this.listbusiness.findIndex(
                (obj) =>
                  obj.folder_id === response.data.your_folder[0]["full_path_id"]
              );
              console.log("user_id_biz", user_id_biz);
              // this.parentname_biz = response.data.your_folder[0]["folder_name"];
              // this.backto_folder = response.data.your_folder[0]["folder_id"];
              // this.parentdirectory = response.data.folder[0]["account_id"];
              // ให้แสดงเป็นชื่อบริษัทที่เลือก
              response.data.your_folder[0]["folder_status"] === "R" ?
                this.parentname_biz = this.business_name :
                this.parentname_biz = response.data.your_folder[0]["folder_name"];
              this.backto_folder = response.data.your_folder[0]["parent_folder_id"];
              this.parentdirectory = response.data.your_folder[0]["account_id"];
              this.folderid = response.data.your_folder[0]["account_id"];
              this.account_active_inbiz =
                response.data.your_folder[0]["account_id"];
              this.full_path = response.data.your_folder[0]["full_path"];
              this.moveto = response.data.your_folder[0]["folder_id"];
              console.log("this.folderid", this.folderid);
            }
            this.item = "";
            this.clicker();
            this.createprogress = false;
          } else {
            this.listbusiness = [];
            this.createprogress = false;
            console.log("");
          }
        })
        .catch((err) => {
          this.listbusiness = [];
          this.createprogress = false;
          console.log(err);
        });
    },
    async searchchildfolder_back(parameter2, account_active_inbizs) {
      this.createprogress = true;
      console.log("this.full_path ", this.full_path);
      if (this.full_path === "Mainfolder") {
        this.fn_getlist();
      } else {
        console.log("เข้าาาาาาาาา");
        console.log("parameter2", parameter2);
        console.log("account_active_inbizs", account_active_inbizs);
        this.listbusiness = [];
        this.targetfolder = "";
        let payload = {
          user_id: this.dataUsername,
          folder_id: parameter2,
          // account_id:this.dataAccountId,
          account_id: account_active_inbizs,
          status_folder: "",
        };
        console.log("payload searchdirectory", payload);
        let auth = await gbfGenerate.generateToken();
        let apiMoveFolder = this.dataAccountActive.type === 'Citizen' ?
        this.checktab === 2 ? '/api/v2/search_folder_byid_admin_ignore' : '/api/v2/search_folder_byid' : '/api/v2/search_folder_byid_admin_ignore' 
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_SELECT_FOLDER + apiMoveFolder,
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              // this.targetfolder = Folder ID ที่ต้องการย้าย
              console.log(response.data);
              if (response.data.folder.length > 0) {
                //กรณีที่ F

                if (response.data.your_folder[0]["folder_status"] === "R") {
                  console.log("R&Citizen");
                  this.moveto = response.data.your_folder[0]["folder_id"];
                  //  this.parentdirectory = response.data.folder[0]["account_id"];
                  // this.enableback = true;
                  this.parentdirectory = response.data.your_folder[0]["account_id"];
                  this.enableback = false;
                  this.folderid = response.data.your_folder[0]["account_id"];

                  this.account_active_inbiz =
                    response.data.your_folder[0]["account_id"];
                  // this.backto_folder =
                  //   response.data.your_folder[0]["folder_id"];
                  this.backto_folder =
                    response.data.your_folder[0]["parent_folder_id"];
                  // this.parentname_biz =
                  //   response.data.your_folder[0]["folder_name"];
                  // ให้แสดงเป็นชื่อบริษัทที่เลือก
                  this.parentname_biz = this.business_name
                  this.full_path = response.data.your_folder[0]["full_path"];
                } else {
                  // ? ถ้าเป็น null ให้ไปที่อยู่ของโฟลเดอร์มัน
                  this.moveto = response.data.your_folder[0]["folder_id"];
                  // this.parentdirectory = response.data.folder[0]["account_id"];
                  this.parentdirectory = response.data.your_folder[0]["account_id"];
                  this.folderid = response.data.your_folder[0]["account_id"];
                  this.enableback = false;
                  this.account_active_inbiz =
                    response.data.your_folder[0]["account_id"];
                  // this.backto_folder =
                  //   response.data.your_folder[0]["folder_id"];
                  this.backto_folder =
                    response.data.your_folder[0]["parent_folder_id"];
                  //! ของเดิมใช้ folder_name
                  this.parentname_biz =
                    response.data.your_folder[0]["folder_name"];
                  this.full_path = response.data.your_folder[0]["full_path"];
                }
                let i;
                for (i = 0; i < response.data.folder.length; i++) {
                  this.listbusiness.push(response.data.folder[i]);
                }
                console.log(
                  "this.account_active_inbiz1",
                  this.account_active_inbiz
                );
              } else {
                console.log(this.listbusiness);
                // console.log(this.listbusiness[0].folder_id);
                console.log(response.data.your_folder[0]["full_path_id"]);

                // let user_id_biz = this.listbusiness.findIndex((obj) => obj.folder_id === response.data.your_folder[0]["full_path_id"]);
                // console.log("user_id_biz",user_id_biz);
                // this.parentname_biz =
                //   response.data.your_folder[0]["folder_name"];
                // this.backto_folder = response.data.your_folder[0]["folder_id"];
                // this.parentdirectory = response.data.folder[0]["account_id"];
                // this.folderid = response.data.your_folder[0]["account_id"];
                // ให้แสดงเป็นชื่อบริษัทที่เลือก
                response.data.your_folder[0]["folder_status"] === "R" ?
                  this.parentname_biz = this.business_name :
                  this.parentname_biz = response.data.your_folder[0]["folder_name"];
                this.backto_folder = response.data.your_folder[0]["parent_folder_id"];
                console.log("this.folderid", this.folderid);
                this.account_active_inbiz =
                  response.data.your_folder[0]["account_id"];
                console.log(
                  "this.account_active_inbiz2",
                  this.account_active_inbiz
                );
                this.full_path = response.data.your_folder[0]["full_path"];
              }
              this.createprogress = false;
            } else {
              this.listbusiness = [];
              this.createprogress = false;
              console.log("");
            }
            this.item_ = "";
            this.clickerBusiness();
          })
          .catch((err) => {
            this.listbusiness = [];
            this.createprogress = false;
            console.log(err);
          });
      }
    },

    async searchchildfolder_old(parameter2) {
      let payload;
      payload = {
        user_id: this.dataUsername,
        folder_id: parameter2,
        account_id: this.dataAccountId,
        status_folder: "",
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_SELECT_FOLDER +
          "/api/v2/search_folder_byid",
        payload,
        {
          headers: { Authorization: auth.code },
        }
      );
      try {
        this.item = "";
        this.listdirectory = [];
        if (response.data.status === "OK") {
          this.createprogress = false;
          if (this.dataAccountActive["type"] === "Citizen") {
            if (response.data.your_folder[0]["folder_status"] === "R") {
              this.enableback = true;
            } else {
              this.enableback = false;
            }
            let b = 0;
            for (b = 0; b < response.data.folder.length; b++) {
              if (
                this.filedata["file_id"] ===
                  response.data.folder[b]["folder_id"] &&
                this.filedata["file_type"] === "folder"
              ) {
                continue;
              } else {
                this.listdirectory.push(response.data.folder[b]);
              }
            }
            // ! parent_folder_id
            this.parentdirectory =
              response.data.your_folder[0]["parent_folder_id"];
            //! ของเดิมใช้ folder_name
            this.parentname = response.data.your_folder[0]["folder_name"];
            this.moveto = response.data.your_folder[0]["folder_id"];
          } else {
            if (response.data.your_folder[0]["folder_status"] === "R") {
              let checkbusinessfolder = this.dataAccountActive[
                "directory_business"
              ].findIndex((t) => t.department_folder_id === parameter2);
              // console.log(checkbusinessfolder);
              if (checkbusinessfolder === -1) {
                //แสดงว่าอยู่ในหน้า root business
                if (
                  this.dataAccountActive["business_info"]["my_folder_id"] ===
                  parameter2
                ) {
                  this.enableback = false;
                  let a = 0;
                  for (
                    a = 0;
                    a < this.dataAccountActive["directory_business"].length;
                    a++
                  ) {
                    let datafolder = {};
                    datafolder["folder_id"] = this.dataAccountActive[
                      "directory_business"
                    ][a]["department_folder_id"];
                    datafolder["folder_name"] = this.dataAccountActive[
                      "directory_business"
                    ][a]["department_name"];
                    datafolder["folder_status"] = "M";
                    datafolder["parent_folder_id"] = this.dataAccountActive[
                      "business_info"
                    ]["my_folder_id"];
                    datafolder["name_eng"] = this.dataAccountActive[
                      "business_info"
                    ]["first_name_eng"];
                    datafolder["name_th"] = this.dataAccountActive[
                      "business_info"
                    ]["first_name_th"];
                    datafolder["user_id"] = "";
                    this.listdirectory.push(datafolder);
                  }

                  this.parentdirectory = this.dataAccountActive["directory_id"];
                  this.parentname = this.$store.getters.dataTypeusercurrent(
                    this.$store.state.account_active["type"],
                    this.$t("default")
                  );
                  this.moveto = this.dataAccountActive["directory_id"];
                  // แก้ moveto business ในบริษัทกดแล้วย้ายมาหน้า mydrive business
                  // if(this.targetfolder === undefined || this.targetfolder === null || this.targetfolder === ""){
                  //   this.moveto = this.dataAccountActive["business_info"]["my_folder_id"]
                  // }else{
                  //   this.moveto = this.dataAccountActive["directory_id"];
                  // }
                } else if (
                  this.dataAccountActive["directory_id"] ===
                  response.data.your_folder[0]["folder_id"]
                ) {
                  this.enableback = true;
                  let datafolder = {};
                  datafolder["folder_id"] = this.dataAccountActive[
                    "business_info"
                  ]["my_folder_id"];
                  datafolder[
                    "folder_name"
                  ] = this.$store.getters.dataTypeusercurrent(
                    this.$store.state.account_active["type"],
                    this.$t("default")
                  );
                  datafolder["folder_status"] = "M";
                  datafolder["parent_folder_id"] = this.dataAccountActive[
                    "directory_id"
                  ];
                  datafolder["name_eng"] = this.dataAccountActive[
                    "business_info"
                  ]["first_name_eng"];
                  datafolder["name_th"] = this.dataAccountActive[
                    "business_info"
                  ]["first_name_th"];
                  datafolder["user_id"] = "";
                  this.listdirectory.push(datafolder);

                  this.parentdirectory = this.dataAccountActive["directory_id"];
                  // this.parentname = this.$store.getters.dataTypeusercurrent(this.$store.state.account_active['type'],this.$t('default'))
                  this.moveto = this.dataAccountActive["directory_id"];

                  if (this.$t("default") === "en") {
                    this.parentname = "My Drive";
                  } else {
                    this.parentname = "ไดรฟ์ของฉัน";
                  }
                } else {
                  this.enableback = false;
                  // ! parent_folder_id
                  this.parentdirectory =
                    response.data.your_folder[0]["parent_folder_id"];
                  // ! folder_name
                  this.parentname = response.data.your_folder[0]["folder_name"];
                  this.moveto = response.data.your_folder[0]["folder_id"];
                }

                let b = 0;
                for (b = 0; b < response.data.folder.length; b++) {
                  if (
                    this.filedata["file_id"] ===
                      response.data.folder[b]["folder_id"] &&
                    this.filedata["file_type"] === "folder"
                  ) {
                    continue;
                  } else {
                    this.listdirectory.push(response.data.folder[b]);
                  }
                }
              } else {
                //แสดงว่าอยู่ในหน้า folder department
                this.enableback = false;
                let datafolder = {};
                datafolder["folder_id"] = this.dataAccountActive[
                  "business_info"
                ]["my_folder_id"];
                datafolder[
                  "folder_name"
                ] = this.$store.getters.dataTypeusercurrent(
                  this.$store.state.account_active["type"],
                  this.$t("default")
                );
                datafolder["folder_status"] = "M";
                datafolder["parent_folder_id"] = this.dataAccountActive[
                  "directory_id"
                ];
                datafolder["name_eng"] = this.dataAccountActive[
                  "business_info"
                ]["first_name_eng"];
                datafolder["name_th"] = this.dataAccountActive["business_info"][
                  "first_name_th"
                ];
                datafolder["user_id"] = "";
                this.listdirectory.push(datafolder);
                // this.listdirectory = response.data.folder;
                let b = 0;
                for (b = 0; b < response.data.folder.length; b++) {
                  if (
                    this.filedata["file_id"] ===
                      response.data.folder[b]["folder_id"] &&
                    this.filedata["file_type"] === "folder"
                  ) {
                    continue;
                  } else {
                    this.listdirectory.push(response.data.folder[b]);
                  }
                }

                this.parentdirectory = this.dataAccountActive["directory_id"];
                //! ของเดิมใช้ folder_name
                this.parentname = response.data.your_folder[0]["folder_name"];
                this.moveto = response.data.your_folder[0]["folder_id"];
              }
            } else {
              this.listdirectory = response.data.folder;
              // this.parentdirectory = this.dataAccountActive["directory_id"];
              // this.parentname = this.$store.getters.dataTypeusercurrent(
              //   this.$store.state.account_active["type"],
              //   this.$t("default")
              // );
              //! parent_folder_id
              this.parentdirectory =
                response.data.your_folder[0]["parent_folder_id"];
              //! ของเดิมใช้ folder_name
              this.parentname = response.data.your_folder[0]["folder_name"];
              this.moveto = response.data.your_folder[0]["folder_id"];
            }
          }
        } else {
          this.listdirectory = [];
          this.createprogress = false;
        }
      } catch (ex) {
        this.createprogress = false;
        console.log(ex);
      }
    },

    searchRootfolder(parameter) {
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.account_active["directory_id"],
        status_folder: "",
      };
      this.axios
        .post(this.SERVICE_ONEBOX + "api/v2/search_folder", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            this.createprogress = false;
            this.listdirectory = response.data.folder;
            this.parentdirectory = "";
            this.parentname = "ไดรฟ์ของฉัน";
            this.moveto = "null";
          } else {
            this.createprogress = false;
          }
        })
        .catch((error) => {
          this.createprogress = false;
          console.log(error);
        });
    },
  },
};
</script>
<style></style>
